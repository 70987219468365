<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card>
      <v-toolbar small color="white" flat>
        <v-toolbar-title>Pago realizado</v-toolbar-title>

        <v-divider class="mx-2 " inset vertical> </v-divider>

        <v-btn
          color="primary"
          small
          class="ma-2 white--text"
          v-if="pago.status_id == 'PMNT_NOT_PAID'"
        >
          <v-icon left>mdi-send</v-icon>
          Completar
        </v-btn>

        <v-btn
          color="primary"
          small
          class="ma-2 white--text"
          v-if="pago.status_id == 'PMNT_RECEIVED'"
          @click="anularPago()"
        >
          <v-icon left>mdi-cancel</v-icon>
          Anular
        </v-btn>

        <v-btn
          color="blue"
          small
          v-if="pago.payment_method_type_id == 'EFT_ACCOUNT'"
          class="ma-2 white--text"
          @click="abrirDialogCpagar"
          >Devolucion de transferencia
        </v-btn>

        <v-btn
          color="blue"
          small
          class="ma-2 white--text"
          @click="verAplicaciones"
          v-if="estaAnulado == false"
        >
          <v-icon left>mdi-order-bool-ascending-variant</v-icon>
          Aplicar pago
        </v-btn>
        <v-spacer></v-spacer>

        <!-- <v-menu offset-y v-if="cabecera.status_id=='INVOICE_READY'">
		      <template v-slot:activator="{ on, attrs }">
		        <v-btn		          
		        	class="ma-2 white--text"
		          	color="blue"
		          	dark
		          	v-bind="attrs"
		          	v-on="on"
		           small
		           
		        >
            <v-icon>mdi-plus</v-icon>
		          Ingresar pago
		        </v-btn>
		      </template>
		      <v-list>
		        <v-list-item
		          v-for="(item, index) in menuPagos"
		          :key="index"		
		          @click="ingPago(index)"          
		        >
		          <v-list-item-title>{{ item.title }}</v-list-item-title>
		        </v-list-item>
		      </v-list>
		    </v-menu> -->
      </v-toolbar>

      <v-row>
        <v-col class="pl-12 pt-6 pb-6">
          <v-row>
            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                label="Codigo"
                class="md-6 white--text"
                v-model="pago.payment_id"
                small
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>

            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                label="Fecha"
                class="md-6 white--text"
                v-model="pago.effective_date"
                small
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="12" md="12" class="pt-0 pb-0">
              <v-text-field
                label="Proveedor"
                class="md-6 white--text"
                v-model="pago.cliente"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                label="Forma de pago"
                class="md-6"
                v-model="pago.forma_pago"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                v-if="esTarjeta"
                label="Tarjeta de crédito"
                class="md-6"
                v-model="pago.card_type"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                v-if="esTransferencia == true"
                label="Cuenta bancaria"
                class="md-6"
                v-model="pago.fin_account_name"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
            <v-col sm="3" md="3" class="pt-0 pb-0">
              <v-text-field
                :readonly="true"
                v-if="esTransferencia == true"
                label="Transaccion libro banco"
                class="md-6"
                v-model="pago.fin_account_trans_id"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
            <v-col sm="3" md="3" class="pt-0 pb-0">
              <v-text-field
                v-if="esTransferencia == true"
                :readonly="true"
                label="Estado transaccion"
                class="md-6"
                :append-icon="estadoTransaccionBancoIcono"
                v-model="estadoTransaccionBanco"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                label="Monto"
                class="md-6"
                v-model="pago.amount"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>

            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                label="Referencia"
                class="md-6"
                v-model="pago.payment_ref_num"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                label="Caja"
                class="md-6"
                v-model="pago.caja_id"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
            <v-col sm="3" md="3" class="pt-4 pb-0">
              <v-btn
                v-if="pago.caja_id != null"
                link
                color="green"
                text
                target="_new"
                :disabled="estaAnulado"
                :href="`/ver-caja/${pago.caja_id}`"
                ><v-icon>mdi-eye</v-icon>Ver caja: {{ pago.caja_id }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <h3>Saldo: {{ (pago.amount - pago.pagado).toFixed(2) }}</h3>
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :items="itemsPago"
            :items-per-page="itemsPerPage"
            :loading="loadingTable"
            hide-default-footer
            :key="`list-sales-invoices-${tenantId}`"
            class="elevation-1"
          >
            <template v-slot:item.opcion="{ item }">
              <v-btn
                v-if="pago.payment_type_id != 'CRUCE_NCC'"
                icon
                color="red"
                target="_blank"
                @click="borrarAplicacionPago(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.invoice_number="{ item }">
              <v-btn
                v-if="item.invoice_id != null"
                icon
                color="green"
                target=" _blank"
                :to="`/ver-factura-compra/${item.invoice_id}`"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <span v-if="item.invoice_id != null">
                {{ item.codigo_establecimiento }}-{{
                  item.codigo_punto_emision
                }}-{{ item.invoice_number }}
              </span>
            </template>

            <template v-slot:item.to_payment_id="{ item }">
              <span v-if="item.to_payment_id == pago.payment_id">
                <v-btn
                  v-if="item.invoice_id == null"
                  icon
                  color="green"
                  target="_blank"
                  :to="`/ver-pago/${item.payment_id}`"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                {{ item.payment_id }}
              </span>
              <span v-if="item.to_payment_id != pago.payment_id">
                <v-btn
                  v-if="item.invoice_id == null"
                  icon
                  color="green"
                  target="_blank"
                  :to="`/ver-pago/${item.to_payment_id}`"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                {{ item.to_payment_id }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-row>
        <v-col cols="12" md="12">
          <v-expansion-panels accordion v-model="panel">
            <v-expansion-panel class="px-0 pb-0">
              <v-expansion-panel-header color="blue-grey lighten-5">
                ASIENTOS CONTABLES
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <AccountingEntries :asientos="asientos" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card>

    <!-- DIALOGO APLICACIONES DEL PAGO-->
    <v-dialog v-model="dialogAplicaciones" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Aplicar pago a</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formAplicacionesPago">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="10" md="10">
                  <v-autocomplete
                    v-model="facturaAplicar"
                    :items="facturasPend"
                    item-value="invoice_id"
                    item-text="invoice_number"
                    dense
                    label="Documento"
                    @change="setMonto"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            item.codigo_establecimiento +
                              '-' +
                              item.codigo_punto_emision +
                              '-' +
                              item.invoice_number
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="`${item.saldo} - ${item.due_date}`"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
                  <v-text-field label="Monto" v-model="montoAplicar">
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0"> </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            small
            text
            @click="dialogAplicaciones = false"
          >
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            solid
            small
            class="white--text"
            @click="aplicarDocPago"
          >
            <v-icon>mdi-check</v-icon>
            Aplicar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOGO DE SOLICITUD DE CHEQUE -->
    <v-dialog v-model="dialogGenerarCpagar" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Generar cuenta por pagar</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formGenerarCpagar">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="10" md="10">
                  <v-select
                    v-model="datosDevTransaccion.fin_account_id"
                    small
                    item-text="fin_account_name"
                    item-value="fin_account_id"
                    :items="cuentasBancarias"
                    label="Cuenta bancaria"
                    :rules="finAccountRule"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
                  <v-text-field
                    v-model="datosDevTransaccion.ref1"
                    label="Referencia devolucion*"
                    type="text"
                    :append-icon="resRefCheque"
                    :rules="[refRule, refExiste]"
                    append-outer-icon="mdi-magnify"
                    small
                    :suffix="valorChequeEc"
                    @click:append-outer="
                      buscarRefBanco('001155', datosDevTransaccion.ref1)
                    "
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
                  <v-text-field
                    v-model="datosDevTransaccion.refCosto"
                    label="Referencia costo"
                    type="text"
                    append-icon="mdi-magnify"
                    small
                    :suffix="costoChequeEc"
                    :rules="[validarRefCosto]"
                    @click:append="
                      buscarRefBanco('098426', datosDevCheque.refCosto)
                    "
                  ></v-text-field>
                </v-col> -->
              </v-row>
            </v-form>
          </v-container>
          <small>*campos obligatorios</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogGenerarCpagar = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            solid
            class="white--text"
            @click="generarCuentaPorPagar()"
          >
            Crear cuenta por pagar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FIN DIALOGO SOLICITUD DE CHEQUE -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import AccountingEntries from "../general/AccountingEntries";
import ExpandableFilters from "../general/ExpandableFilters";
import Vue from "vue";

export default {
  name: "FormVerPagoSalienteComponent",
  components: {
    ExpandableFilters,
    AccountingEntries,
  },
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Codigo", value: "payment_id" },
      { text: "Monto", value: "amount_applied", align: "end" },
      { text: "Factura", value: "invoice_number" },
      { text: "Pago", value: "to_payment_id" },
    ],
    lista: [],
    options: {},
    dialog: false,
    dialogAplicaciones: false,
    notifications: false,
    overlay: false,
    pago: {
      estado: "",
      payment_id: "",
      effective_date: "",
      party_id_from: "",
      party_id_to: "",
      payment_type_id: "",
      payment_method_type_id: "",
      pagado_por: "",
      pagado_a: "",
      monto: 0,
      pagado: 0,
      payment_ref_num: "",
      payment_method_id: "",
    },
    itemsEstado: [
      { estadoId: "INVOICE_READY", estadoNombre: "Listo" },
      { estadoId: "INVOICE_PAID", estadoNombre: "Pagado" },
    ],
    itemsPago: [],

    headItems: [
      { text: "Opciones", value: "opcion", align: "center", width: 50 },
      { text: "No.", value: "secId", align: "start" },
      { text: "Descripcion", value: "description", align: "start" },
      { text: "Cantidad", value: "cantidad", align: "end" },
      { text: "Precio", value: "valorUnitario", align: "end" },
      { text: "%IVA", value: "pct_iva", align: "end" },
      { text: "IVA", value: "valor_iva", align: "end" },
      { text: "Total", value: "total", align: "end" },
    ],
    headPagos: [
      {
        text: "Tipo",
        value: "payment_method_type",
        align: "center",
        width: 150,
      },
      { text: "Referencia", value: "payment_ref_num", align: "start" },
      { text: "Fecha", value: "effective_date", align: "start" },
      { text: "Monto", value: "amount_applied", align: "start" },
    ],

    asientos: [],
    panel: "",
    total_pagado: "",
    currentPage: 0,
    pageCount: 1,
    itemsPerPage: 20,
    asientos: [],
    facturasPend: [],
    facturaAplicar: "",
    montoAplicar: 0,

    cuentasBancarias: [],
    finAccountRule: [(v) => !!v || "El campo es obligatorio"],
    refRule: (v) => !!v || "El campo es requerido",
    existe_pago_cheque: false,

    datosDevTransaccion: { refCosto: "", ref1: "", fin_account_id: "" },
    refChequeEc: "",
    valorChequeEc: "",
    refCostoEc: "",
    costoChequeEc: "",
    dialogGenerarCpagar: false,

    resRefCheque: "",
  }),
  watch: {
    options: {
      handler() {
        this.cargarPago(false);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("master", ["loadingTable", "user", "tenantId"]),

    ...mapGetters("access", ["btnAbrirCaja"]),

    refExiste() {
      if (this.valorChequeEc == 0 || this.valorChequeEc == "")
        return "No se encontró la referencia";
      else return true;
    },

    validarRefCosto() {
      if (
        this.datosDevTransaccion.refCosto != "" &&
        (this.costoChequeEc == 0 || this.costoChequeEc == "")
      ) {
        return "No se encontró una transacción";
      } else {
        return true;
      }
    },
    esTransferencia() {
      if (this.pago.payment_method_type_id == "EFT_ACCOUNT") {
        return true;
      }
      return false;
    },
    estaPagado() {
      if (this.pago.status_id == "PMNT_NOT_PAID") {
        return true;
      }

      return false;
    },
    estaAnulado() {
      if (this.pago.status_id == "PMNT_VOID") {
        return true;
      }

      return false;
    },
    esTarjeta() {
      if (
        this.pago.payment_method_type_id == "CREDIT_CARD" ||
        this.pago.payment_method_type_id == "DEBIT_CARD" ||
        this.pago.payment_method_type_id == "CREDIT_CARD_DIF" ||
        this.pago.payment_method_type_id == "CREDIT_CARD_DIF_INT"
      ) {
        return true;
      }

      return false;
    },
    estadoTransaccionBancoIcono() {
      return this.pago.gl_reconciliation_id == null
        ? "mdi-close-circle"
        : "mdi-check-circle";
    },
    estadoTransaccionBanco() {
      return this.pago.gl_reconciliation_id == null
        ? "No conciliada"
        : "Conciliada";
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarPago() {
      this.setLoadingTable(true);
      this.setUrl("pago-recibido/" + this.$route.params.pagoId);
      this.lista = [];
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.pago = res.data.detail.pago.payment;
          this.itemsPago = res.data.detail.pago.payapp;
          this.asientos = res.data.detail.pago.asientos;
          this.facturasPend = res.data.detail.facturas_pendientes;
          this.setLoadingTable(false);
          //console.log(this.pago)
          this.setTitleToolbar(
            "PAGO #" +
              this.pago.payment_id +
              " - " +
              this.pago.tipo_pago +
              " - " +
              this.pago.estado
          );
        })
        .then(() => {});
    },

    handlePageChange(value) {
      //this.currentPage = value
      this.cargarLista(false);
    },

    cargarCuentasBancarias() {
      this.setLoadingTable(true);

      this.setUrl("cuentas-bancarias");
      this.requestApi({
        method: "GET",
        data: {},
      }).then((res) => {
        this.cuentasBancarias = res.data._embedded.cuentas_bancarias;
        this.setLoadingTable(false);
      });
    },

    // getFormasPago() {

    //     this.setUrl('tipo-pago/CUSTOMER_PAYMENT/formas-pago')
    //     this.requestApi({
    //         method: 'GET',
    //         data: {}
    //     }).then(res => {

    //         this.filters[5].items = res.data.detail

    //     }).then(() => {

    //     })

    // },

    verAplicaciones() {
      this.dialogAplicaciones = true;
      this.montoAplicar = 0;
      this.facturaAplicar = "";
    },
    borrarAplicacionPago(pagoApp) {
      Vue.swal({
        html: "Está seguro de eliminar este pago?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("pago-recibido");
          this.requestApi({
            method: "DELETE",
            data: {
              payment_application_id: pagoApp.payment_application_id,
              payment_id: pagoApp.payment_id,
            },
          }).then((res) => {
            //this.dialogPago = false;
            //this.formInvoice(this.$route.params.invoiceId)
            this.cargarPago();
            this.alertNotification({
              param: {
                html: res.data.detail.msg,
              },
            });
          });
        }
      });
    },
    anularPago() {
      //alert('anulando pago')
      this.overlay = true;
      this.setUrl("anular-pago");
      this.requestApi({
        method: "POST",
        data: {
          paymentId: this.pago.payment_id,
        },
      })
        .then((res) => {
          console.log(res);
        })
        .catch(() => {})
        .then(() => {
          this.overlay = false;
        });
    },
    aplicarDocPago() {
      this.overlay = true;
      this.setUrl("payment-application");
      if (this.facturaAplicar == null || this.facturaAplicar == "") {
        alert("Debe seleccionar un documento para aplicar el pago");
        return false;
      }
      if (this.pago.amount - this.pago.pagado < this.montoAplicar) {
        alert("El saldo para aplicar es insuficiente. ");
        return false;
      }
      this.requestApi({
        method: "POST",
        data: {
          payment_id: this.pago.payment_id,
          invoice_id: this.facturaAplicar,
          amount_applied: this.montoAplicar,
        },
      })
        .then((res) => {
          //console.log(res)
          this.dialogAplicaciones = false;
          this.cargarPago();
          this.alertNotification({
            param: {
              html: res.data.res.msg,
              timer: 5000,
            },
          });
        })
        .catch(() => {})
        .then(() => {
          this.overlay = false;
          this.dialogAplicaciones = false;
        });
    },
    setMonto() {
      //console.log(this.facturaAplicar)
      for (let i = 0; i < this.facturasPend.length; i++) {
        if (this.facturasPend[i].invoice_id == this.facturaAplicar) {
          if (
            (this.pago.amount - this.pago.pagado).toFixed(2) >
            this.facturasPend[i].saldo
          ) {
            this.montoAplicar = this.facturasPend[i].saldo;
          } else {
            this.montoAplicar = (this.pago.amount - this.pago.pagado).toFixed(
              2
            );
          }
        }
      }
    },

    abrirDialogCpagar() {
      this.datosDevTransaccion = { refCosto: "", ref1: "", fin_account_id: "" };
      this.refChequeEc = "";
      this.valorChequeEc = "";
      this.refCostoEc = "";
      this.costoChequeEc = "";
      this.dialogGenerarCpagar = true;
    },
    //enviar solicitud de devolucion de cheque
    generarCuentaPorPagar() {
      /*if (!this.$refs.formGenerarCpagar.validate()) {
        return false;
      }*/

      this.setUrl("cuentas-por-pagar");
      this.requestApi({
        method: "POST",
        data: {
          accion: "crearNotaDebitoPorPagar",
          ref1: this.refChequeEc,
          ref2: this.refCostoEc,
          party_id: this.pago.party_id_to,
          //invoice_id_from: this.cabecera.invoice_id,
          fin_account_id: this.datosDevTransaccion.fin_account_id,
        },
      }).then((res) => {
        this.dialogGenerarCpagar = false;

        this.alertNotification({
          param: {
            html: "Nota de debito generada exitosamente",
            timer: 5000,
          },
        });
      });
    },
    //busca la referencia en el libro bancos(banco pichincha)
    buscarRefBanco(codigo, doc) {
      this.setUrl("estado-cuenta");
      this.requestApi({
        method: "POST",
        data: {
          codigo: codigo,
          documento: doc,
        },
      }).then((res) => {
        if (codigo == "001155") {
          if (res.data.concil_estado_cuenta_id == "") {
            this.resRefCheque = "mdi-close-circle-outline";
            this.refChequeEc = "";
            this.valorChequeEc = "";
          } else {
            this.resRefCheque = "mdi-check-circle-outline";
            this.refChequeEc = res.data.concil_estado_cuenta_id;
            this.valorChequeEc = res.data.monto;
          }
        }
        /*if (codigo == "098426") {
          this.refCostoEc = res.data.concil_estado_cuenta_id;
          this.costoChequeEc = res.data.monto;
        }*/
      });
    },
  },

  mounted() {
    //this.getFormasPago()
    this.cargarCuentasBancarias();
  },
};
</script>
